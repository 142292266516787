import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/CalendarDate/Picker/CalendarTableData/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/CalendarDate/Picker/CalendarTableData/index.module.css:injector.js";
export default new Proxy({
  "calendarTableData": "index-module__gF2vkG__CalendarTableData",
  "button": "index-module__gF2vkG___Button",
  "buttonText1": "index-module__gF2vkG___ButtonText1",
  "tableStatusText": "index-module__gF2vkG___TableStatusText",
  "CalendarTableData": "index-module__gF2vkG__CalendarTableData",
  "_Button": "index-module__gF2vkG___Button",
  "_ButtonText1": "index-module__gF2vkG___ButtonText1",
  "_TableStatusText": "index-module__gF2vkG___TableStatusText"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
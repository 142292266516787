import "esbuild-css-modules-plugin-ns-css:src/components/shared/components/VisuallyHiddenText/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/shared/components/VisuallyHiddenText/index.module.css:injector.js";
export default new Proxy({
  "visuallyHiddenText": "index-module__KFwSHG__VisuallyHiddenText",
  "VisuallyHiddenText": "index-module__KFwSHG__VisuallyHiddenText"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
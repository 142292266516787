import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/index.module.css:injector.js";
export default new Proxy({
  "orderForm": "index-module__xIwuJa__OrderForm",
  "closeButton": "index-module__xIwuJa___CloseButton",
  "detail": "index-module__xIwuJa___Detail",
  "errorMessage": "index-module__xIwuJa___ErrorMessage",
  "errorMessageText": "index-module__xIwuJa___ErrorMessageText",
  "form": "index-module__xIwuJa___Form",
  "formItem": "index-module__xIwuJa___FormItem",
  "formItemNote": "index-module__xIwuJa___FormItemNote",
  "formLabel": "index-module__xIwuJa___FormLabel",
  "formLabelText": "index-module__xIwuJa___FormLabelText",
  "price": "index-module__xIwuJa___Price",
  "priceAndAddButton": "index-module__xIwuJa___PriceAndAddButton",
  "priceNote": "index-module__xIwuJa___PriceNote",
  "priceOff": "index-module__xIwuJa___PriceOff",
  "priceText1": "index-module__xIwuJa___PriceText1",
  "priceText2": "index-module__xIwuJa___PriceText2",
  "priceTexts": "index-module__xIwuJa___PriceTexts",
  "select": "index-module__xIwuJa___Select",
  "title": "index-module__xIwuJa___Title",
  "titleLabel": "index-module__xIwuJa___TitleLabel",
  "titleName": "index-module__xIwuJa___TitleName",
  "img": "index-module__xIwuJa__img",
  "label": "index-module__xIwuJa__label",
  "name": "index-module__xIwuJa__name",
  "OrderForm": "index-module__xIwuJa__OrderForm",
  "_CloseButton": "index-module__xIwuJa___CloseButton",
  "_Detail": "index-module__xIwuJa___Detail",
  "_ErrorMessage": "index-module__xIwuJa___ErrorMessage",
  "_ErrorMessageText": "index-module__xIwuJa___ErrorMessageText",
  "_Form": "index-module__xIwuJa___Form",
  "_FormItem": "index-module__xIwuJa___FormItem",
  "_FormItemNote": "index-module__xIwuJa___FormItemNote",
  "_FormLabel": "index-module__xIwuJa___FormLabel",
  "_FormLabelText": "index-module__xIwuJa___FormLabelText",
  "_Price": "index-module__xIwuJa___Price",
  "_PriceAndAddButton": "index-module__xIwuJa___PriceAndAddButton",
  "_PriceNote": "index-module__xIwuJa___PriceNote",
  "_PriceOff": "index-module__xIwuJa___PriceOff",
  "_PriceText1": "index-module__xIwuJa___PriceText1",
  "_PriceText2": "index-module__xIwuJa___PriceText2",
  "_PriceTexts": "index-module__xIwuJa___PriceTexts",
  "_Select": "index-module__xIwuJa___Select",
  "_Title": "index-module__xIwuJa___Title",
  "_TitleLabel": "index-module__xIwuJa___TitleLabel",
  "_TitleName": "index-module__xIwuJa___TitleName"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/App.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/App.module.css:injector.js";
export default new Proxy({
  "app": "App-module__LdczxW__App",
  "dialog": "App-module__LdczxW___Dialog",
  "App": "App-module__LdczxW__App",
  "_Dialog": "App-module__LdczxW___Dialog"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/PlanHelp/Dialog/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/PlanHelp/Dialog/index.module.css:injector.js";
export default new Proxy({
  "helpDialog": "index-module__eDrPha__HelpDialog",
  "helpDialogBody": "index-module__eDrPha___HelpDialogBody",
  "helpDialogBodyHeadingText": "index-module__eDrPha___HelpDialogBodyHeadingText",
  "helpDialogBodyText": "index-module__eDrPha___HelpDialogBodyText",
  "helpDialogCloseButton": "index-module__eDrPha___HelpDialogCloseButton",
  "helpDialogInner": "index-module__eDrPha___HelpDialogInner",
  "helpDialogTitle": "index-module__eDrPha___HelpDialogTitle",
  "helpDialogTitleText": "index-module__eDrPha___HelpDialogTitleText",
  "HelpDialog": "index-module__eDrPha__HelpDialog",
  "_HelpDialogBody": "index-module__eDrPha___HelpDialogBody",
  "_HelpDialogBodyHeadingText": "index-module__eDrPha___HelpDialogBodyHeadingText",
  "_HelpDialogBodyText": "index-module__eDrPha___HelpDialogBodyText",
  "_HelpDialogCloseButton": "index-module__eDrPha___HelpDialogCloseButton",
  "_HelpDialogInner": "index-module__eDrPha___HelpDialogInner",
  "_HelpDialogTitle": "index-module__eDrPha___HelpDialogTitle",
  "_HelpDialogTitleText": "index-module__eDrPha___HelpDialogTitleText"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/CalendarDate/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/CalendarDate/index.module.css:injector.js";
export default new Proxy({
  "calendarDialog": "index-module__2DUCgG___CalendarDialog",
  "_CalendarDialog": "index-module__2DUCgG___CalendarDialog"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
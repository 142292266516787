import "esbuild-css-modules-plugin-ns-css:src/components/shared/components/FreeTextArea/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/shared/components/FreeTextArea/index.module.css:injector.js";
export default new Proxy({
  "freeTextArea": "index-module__lXCqAq__FreeTextArea",
  "text": "index-module__lXCqAq___Text",
  "FreeTextArea": "index-module__lXCqAq__FreeTextArea",
  "_Text": "index-module__lXCqAq___Text"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      

const content = __content_placeholder__;
const digest = __digest_placeholder__;  
const inject = () => {
  setTimeout(() => {
    if (!globalThis.document) {
      return;
    }
    let root = globalThis.document.querySelector("head");
    if (root && root.shadowRoot) {
      root = root.shadowRoot;
    }
    if (!root) {
      root = globalThis.document.head;
    }
    let container = root.querySelector("#_" + digest);
    if (!container) {
      container = globalThis.document.createElement("style");
      container.id = "_" + digest;
      const text = globalThis.document.createTextNode(content);
      container.appendChild(text);
      root.appendChild(container);
    }
  }, 0);
};

export { inject };
    
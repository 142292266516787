import "esbuild-css-modules-plugin-ns-css:src/components/shared/cssModules/customProperties.modules.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/shared/cssModules/customProperties.modules.css:injector.js";
export default new Proxy({
  "properties": "customProperties-modules__BcEXZW__properties"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
import "esbuild-css-modules-plugin-ns-css:src/components/shared/components/ButtonPrimary/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/shared/components/ButtonPrimary/index.module.css:injector.js";
export default new Proxy({
  "buttonPrimary": "index-module__niCLca__ButtonPrimary",
  "ButtonPrimary": "index-module__niCLca__ButtonPrimary"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
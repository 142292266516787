import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/CalendarDate/PickerOpener/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/CalendarDate/PickerOpener/index.module.css:injector.js";
export default new Proxy({
  "calendarDatePickerOpener": "index-module__1M5bcW__CalendarDatePickerOpener",
  "CalendarDatePickerOpener": "index-module__1M5bcW__CalendarDatePickerOpener"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
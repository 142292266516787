import "esbuild-css-modules-plugin-ns-css:src/components/RentalStart/OrderForm/CalendarDate/Picker/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/RentalStart/OrderForm/CalendarDate/Picker/index.module.css:injector.js";
export default new Proxy({
  "calendarDatePicker": "index-module__xxexda__CalendarDatePicker",
  "calendarDialogBody": "index-module__xxexda___CalendarDialogBody",
  "calendarDialogCloseButton": "index-module__xxexda___CalendarDialogCloseButton",
  "calendarDialogTitle": "index-module__xxexda___CalendarDialogTitle",
  "calendarDialogTitleText": "index-module__xxexda___CalendarDialogTitleText",
  "monthHeader": "index-module__xxexda___MonthHeader",
  "monthTitle": "index-module__xxexda___MonthTitle",
  "nav": "index-module__xxexda___Nav",
  "table": "index-module__xxexda___Table",
  "tableHeader": "index-module__xxexda___TableHeader",
  "tableWrapper": "index-module__xxexda___TableWrapper",
  "td": "index-module__xxexda___Td",
  "th": "index-module__xxexda___Th",
  "thead": "index-module__xxexda___Thead",
  "tr": "index-module__xxexda___Tr",
  "CalendarDatePicker": "index-module__xxexda__CalendarDatePicker",
  "_CalendarDialogBody": "index-module__xxexda___CalendarDialogBody",
  "_CalendarDialogCloseButton": "index-module__xxexda___CalendarDialogCloseButton",
  "_CalendarDialogTitle": "index-module__xxexda___CalendarDialogTitle",
  "_CalendarDialogTitleText": "index-module__xxexda___CalendarDialogTitleText",
  "_MonthHeader": "index-module__xxexda___MonthHeader",
  "_MonthTitle": "index-module__xxexda___MonthTitle",
  "_Nav": "index-module__xxexda___Nav",
  "_Table": "index-module__xxexda___Table",
  "_TableHeader": "index-module__xxexda___TableHeader",
  "_TableWrapper": "index-module__xxexda___TableWrapper",
  "_Td": "index-module__xxexda___Td",
  "_Th": "index-module__xxexda___Th",
  "_Thead": "index-module__xxexda___Thead",
  "_Tr": "index-module__xxexda___Tr"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      
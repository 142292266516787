import "esbuild-css-modules-plugin-ns-css:src/components/shared/components/HelpDialogOpener/index.module.css";

import { inject } from "esbuild-css-modules-plugin-ns-js:src/components/shared/components/HelpDialogOpener/index.module.css:injector.js";
export default new Proxy({
  "helpButton": "index-module__FpamVq___HelpButton",
  "_HelpButton": "index-module__FpamVq___HelpButton"
}, {
  get: function(source, key) {
    inject();
    return source[key];
  }
});
      